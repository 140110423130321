// @ts-nocheck
import * as React from 'react';
import InputSelectType from './InputSelectType';
import {
  handleDataTypeChange,
  handleValueChange,
  getArgumentInformations,
  getPathToArguments,
} from '../../utils/inputs';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';

const ItemBoolean = (props: any) => {
  const { item, index, contextHandleDataTypeChange } = props;
  const dispatch = useDispatch();
  const pathToArgument = getPathToArguments({
    contextHandleDataTypeChange,
    addToPath: [index],
  });
  const argument = useSelector(
    (state) =>
      ['formField', ...pathToArgument].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );
  const { value, label } = getArgumentInformations(item);

  return (
    <>
      <InputSelectType
        label={label}
        value={value !== undefined ? value.toString() : ''}
        handleOnChange={(e) =>
          handleValueChange(
            e.target.value,
            index,
            item,
            contextHandleDataTypeChange,
            dispatch,
            updateFormField,
          )
        }
        formControlProps={{
          required: item[Object.keys(item)[2]],
          crequired: item[Object.keys(item)[2]]
            ? item[Object.keys(item)[2]]
            : 'undefined',
          'data-testid': 'c2ae2a7e-66d8-4223-b104-91fca886e2ed',
        }}
        labelProps={{
          id: label,
          sx: { ml: 1 },
        }}
        selectProps={{
          type: item?.python_datatypes?.[listIndex],
          required: item[Object.keys(item)[2]],
          sx: { minWidth: 110 },
          'data-testid': 'a12dffea-27ab-4278-afd1-1a63e5986af2',
          labelId: label,
          id: label,
        }}
        listType="dataTypeBoolean"
      />
      {item?.canModifyDataType && (
        <InputSelectType
          value={item.python_datatype}
          handleOnChange={(e: any) =>
            handleDataTypeChange(
              e.target.value,
              index,
              item,
              contextHandleDataTypeChange,
              argument,
              dispatch,
              updateFormField,
            )
          }
          formControlProps={{
            sx: { ml: 1 },
          }}
          selectProps={{
            sx: { minWidth: 110 },
            'data-testid': '75aaeca2-865f-414f-8f11-2e416fcd532e',
          }}
          listType="dataType"
        />
      )}
    </>
  );
};

export default ItemBoolean;
