// @ts-nocheck
import { useState, useEffect } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBlobs } from '../../slices/blobSlice';
import Button from '@mui/material/Button';

import './EditPipeline.css';

const EditPipeline = () => {
  const [emptyBlobs, setEmptyBlobs] = useState(false);
  const isBlobsLoading = useSelector((state: RootState) => state.blobs.loading);
  const blobsErrorMessage = useSelector(
    (state: RootState) => state.blobs.error,
  );
  const blobsSelector = useSelector((state: RootState) => state.blobs.blobs);
  const blobsStatusSelector = useSelector(
    (state: RootState) => state.blobs.status,
  );

  const dispatch = useDispatch();

  const getBlobs = () => {
    if (!blobsSelector.length && blobsStatusSelector) {
      setEmptyBlobs(true);
    } else if (!blobsSelector.length && !blobsStatusSelector) {
      dispatch(fetchBlobs());
    }
  };

  useEffect(() => {
    getBlobs();
  }, [blobsSelector]);

  useEffect(() => {
    if (isBlobsLoading === false && blobsErrorMessage) {
      enqueueSnackbar(blobsErrorMessage, {
        variant: 'error',
      });
    }
  }, [isBlobsLoading]);

  return (
    <div className="create-pipeline-container">
      {emptyBlobs ? (
        <div className="create-pipeline-div">
          <h4 className="create-pipeline-h1">
            There are no existing pipelines
          </h4>
          <div className="empty-pipeline-container">
            <h5 className="create-pipeline-h1">To create a new pipeline:</h5>
            <Link to="/create-pipeline" className="link-style">
              <Button sx={{ backgroundColor: '#d04a02' }} variant="contained">
                Create pipeline
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <h4 className="create-pipeline-h1">
            Edit an existing Customer Insights pipeline
          </h4>
          <form className="create-pipeline-form">

            <ul>
              {blobsSelector.length > 0 &&
                blobsSelector.map(
                  (item: { name: string; url: string; uid: string }, index) => {
                    return (
                      <li className="item-container" key={blobsSelector}>
                        {item.name}
                        <Link
                          to="/create-pipeline"
                          state={{ LoadUrl: item.url, filePath: item.filepath }}
                          className="link"
                        >
                          EDIT
                        </Link>
                      </li>
                    );
                  },
                )}
            </ul>
            <div className="loading">
              {isBlobsLoading ? <PropagateLoader color="black" /> : ''}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditPipeline;
