import { Box, Button } from '@mui/material';
import React from 'react';

export default function MenuBackNext({
  activeStep,
  handleBack,
  handleNext,
  steps,
}: {
  activeStep: number;
  handleBack: () => void;
  handleNext: (
    e: React.MouseEvent<HTMLButtonElement>,
    activeStep: number,
  ) => void;
  steps: Array<any>;
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      {activeStep === 0 ? (
        ''
      ) : (
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{
            mr: 1,
            color: 'white !important',
            backgroundColor: '#d04a02 !important',
          }}
        >
          Back
        </Button>
      )}
      <Box sx={{ flex: '1 1 auto' }} />
      <Button
        sx={{
          color: 'white !important',
          backgroundColor: '#d04a02 !important',
        }}
        type="submit"
        onClick={(e) => handleNext(e, activeStep)}
      >
        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
      </Button>
    </Box>
  );
}
