// @ts-nocheck
import { RemoveCircleSharp } from '@mui/icons-material';
import { Box } from '@mui/material';

export default function ButtonRemove({
  buttonProps = {},
  isDisabled = false,
  label = 'Remove value',
  className = 'remove-merchant-button',
  onRemove,
}) {
  return (
    <button
      type="button"
      className={className}
      onClick={onRemove}
      disabled={isDisabled}
      {...buttonProps}
    >
      <RemoveCircleSharp />{' '}
      <Box component="span" ml={0.5}>
        {label}
      </Box>
    </button>
  );
}
