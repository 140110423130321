import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import './CardTile.css';

interface CardTileProps {
  title: string;
  desc: string;
  link: string;
  imgLink: any;
  icon: any;
  buttonText: string;
  linkType: string;
}

const CardTile: React.FC<CardTileProps> = (props) => {
  return (
    <Card
      sx={{
        padding: '30px',
        display: 'flex',
        gap: '20px',
        justifyContent: 'space-between',
        backgroundColor: '#fafafa',
        width: '300px',
      }}
      variant="outlined"
    >
      {props.linkType === 'internal' ? (
        <Link to={props.link} className="link-style">
          <h6>{props.title}</h6>
          <p>{props.desc}</p>
          <Button sx={{ backgroundColor: '#d04a02' }} variant="contained">
            {props.buttonText}
          </Button>
        </Link>
      ) : (
        <a
          href={props.link}
          target="_blank"
          className="link-style"
          rel="noreferrer"
        >
          <h6>{props.title}</h6>
          <p>{props.desc}</p>
          <Button sx={{ backgroundColor: '#d04a02' }} variant="contained">
            {props.buttonText}
          </Button>
        </a>
      )}
    </Card>
  );
};

export default CardTile;
