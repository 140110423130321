import React, { useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export const handleEmailChange = (
  index,
  _emails,
  fieldName,
  dataSources,
  dispatchFunction,
  dispatch,
) => {
  const updatedValues = structuredClone(dataSources);
  updatedValues[index][fieldName] = _emails;
  dispatch(dispatchFunction({ data: updatedValues }));
};

const MultiEmail = ({
  emailsArray,
  handleOnChange,
  label,
  id = crypto.randomUUID,
  className,
  required,
  ...props
}) => {
  const emails = emailsArray
    ? Array.isArray(emailsArray)
      ? emailsArray
      : [emailsArray]
    : [];
  const [isFocused, setIsFocused] = useState(emails?.length > 0 ? true : false);
  const [inputValue, setInputValue] = useState('');

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (emails?.length === 0 && !inputValue) {
      setIsFocused(false);
    }
  };

  return (
    <div className={`react-multi-email-div ${className ? className : ''}`}>
      <FormControl>
        <InputLabel
          shrink={isFocused}
          required={required}
          className="react-multi-email-label"
          htmlFor={id}
        >
          {label}
        </InputLabel>
        <ReactMultiEmail
          {...props}
          id={id}
          emails={emails}
          onChange={(_emails) => {
            setInputValue(() => '');
            handleOnChange(_emails);
          }}
          onChangeInput={(value) => setInputValue(() => value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={email}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />
      </FormControl>
    </div>
  );
};

export default MultiEmail;
