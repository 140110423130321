import axios from 'axios';
import getPkce from 'oauth-pkce';
import config from '../config';
import { CheckAuthorization } from './authorization.service';
import { setCookieWithEncryption } from '../utils/crypto';
import { enqueueSnackbar } from 'notistack';

export const CheckAuthentication = async () => {
  PkceAuthentication();
  return true;
};

export const PkceAuthentication = async () => {
  const pkce: { verifier: string; challenge: string } = await new Promise(
    (resolve) => {
      getPkce(50, (error, { verifier, challenge }) => {
        if (error) {
          throw error;
        }
        resolve({ verifier, challenge });
      });
    },
  );

  const codeVerifier = pkce.verifier;
  const codeChallenge = pkce.challenge;

  setCookieWithEncryption('codeVerifier', codeVerifier, {
    sameSite: 'strict',
    secure: true,
    expires: 1,
  });

  const openam = {
    authorization_endpoint: config.authorization_endpoint,
    app_url: config.redirectionUrl,
    client_id: config.client_id,
  };

  const openAmURL = `${
    openam.authorization_endpoint
  }?response_type=code&client_id=${
    openam.client_id
  }&redirect_uri=${encodeURIComponent(
    openam.app_url,
  )}&scope=openid&code_challenge=${codeChallenge}&code_challenge_method=S256`;

  window.location.href = openAmURL;
};

export const exchangeCodeForToken = async (
  Code: string,
  code_verifier: string,
) => {
  const openam = {
    token_endpoint: config.token_endpoint,
    app_url: config.redirectionUrl,
    client_id: config.client_id,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
  };
  const tokenEndpoint = openam.token_endpoint ? openam.token_endpoint : '';
  const Client_id = openam.client_id;
  const Redirect_uri = openam.app_url;

  const options = {
    code: Code,
    client_id: Client_id,
    code_verifier: code_verifier,
    grant_type: 'authorization_code',
    redirect_uri: Redirect_uri,
    client_secret: openam.client_secret,
  };
  try {
    const response: any = await axios.post(tokenEndpoint, options, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
    setCookieWithEncryption('access_token', response.data.access_token, {
      sameSite: 'strict',
      secure: true,
      expires: 1,
    });
    //   setCookieWithEncryption('response_token_object', response.data, {
    //   sameSite: 'strict',
    //   secure: true,
    //   expires: 1,
    // });
    await CheckAuthorization();
  } catch (error: any) {
    enqueueSnackbar(error.message, {
      variant: 'error',
    });
  }
};
