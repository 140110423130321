// @ts-nocheck
import * as React from 'react';
import NumericTextField from '../NumericTextField/NumericTextField';
import {
  getDataType,
  handleDataTypeChange,
  getArgumentInformations,
  handleValueChange,
  getPathToArguments,
} from '../../utils/inputs';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import InputSelectType from './InputSelectType';
const ItemElse = ({
  item,
  index,
  contextHandleDataTypeChange,
}: {
  item: any;
  index: number;
  contextHandleDataTypeChange: any;
}) => {
  const { inputProps, placeholder, value, label } =
    getArgumentInformations(item);
  const dispatch = useDispatch();
  const pathToArgument = getPathToArguments({
    contextHandleDataTypeChange,
    addToPath: [index],
  });
  const argument = useSelector(
    (state) =>
      ['formField', ...pathToArgument].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );
  return (
    <>
      <NumericTextField
        required={item[Object.keys(item)[2]]}
        key={label}
        id={label}
        type={getDataType(item.python_datatype)}
        label={label}
        variant="standard"
        value={value}
        onChange={(e: any) =>
          handleValueChange(
            e.target.value,
            index,
            item,
            contextHandleDataTypeChange,
            dispatch,
            updateFormField,
          )
        }
        sx={{
          ml: 1.7,
        }}
        inputProps={inputProps}
        placeholder={placeholder()}
        data-testid="36eefcad-0df6-4657-bfa7-faab76df2b5f"
        dataType={item.python_datatype}
      />
      {item?.canModifyDataType ? (
        <InputSelectType
          value={item.python_datatype}
          handleOnChange={(e: any) =>
            handleDataTypeChange(
              e.target.value,
              index,
              item,
              contextHandleDataTypeChange,
              argument,
              dispatch,
              updateFormField,
            )
          }
          formControlProps={{
            sx: { ml: 1 },
          }}
          selectProps={{
            sx: { minWidth: 110 },
            'data-testid': 'e7f5a507-8705-4273-a2e2-d400b65cb477',
          }}
          listType="dataType"
        />
      ) : null}
    </>
  );
};

export default ItemElse;
