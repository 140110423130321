import http from './http';
import {
  setCookieWithEncryption,
  getCookieWithDecryption,
} from '../utils/crypto';
import config from '../config';
import axios from 'axios';

export const CheckAuthorization = async () => {
  const openam = {
    user_profile_url: config.user_profile_url,
  };

  const token = getCookieWithDecryption('access_token');
  const options = {
    access_token: token ? token : '',
  };
  const response = await axios.post(openam.user_profile_url, options, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  const firstName = getFirstName(response.data.upn);
  const lastName = getLastName(response.data.upn);
  const access_token = options.access_token;
  const result = {
    access_token: access_token,
    given_name: firstName,
    family_name: lastName,
    unique_name: firstName + ' ' + lastName,
    guid: response.data.sub || response.data.uid,
    mail: response.data.upn.replace(/\@.*/g, '@pwc.com'),
    claims: null,
    access: false,
  };
  await fetchAuthorise(result);
};

const getFirstName = (varEmailAddress: any) => {
  const emailAddressWithoutDomain = varEmailAddress.split('@')[0];
  let firstName = emailAddressWithoutDomain.substr(
    0,
    emailAddressWithoutDomain.indexOf('.'),
  );
  firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  return firstName;
};

const getLastName = (varEmailAddress: any) => {
  const emailAddressWithoutDomain = varEmailAddress.split('@')[0];
  let lastName = emailAddressWithoutDomain.substr(
    emailAddressWithoutDomain.indexOf('.') + 1,
    emailAddressWithoutDomain.length - emailAddressWithoutDomain.indexOf('.'),
  );
  lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
  return lastName;
};

const fetchAuthorise = async (props: any) => {
  const formField = {
    user: props.mail,
  };
  const response: any = await http.post('/api/authorise/', formField);
  if (response.has_access) {
    setCookieWithEncryption('access', true, {
      expires: 1, // Will expire after 24 hours (value is in number of sec.)
    });
    return true;
  } else {
    setCookieWithEncryption('access', false, {
      expires: 1, // Will expire after 24 hours (value is in number of sec.)
    });
    return false;
  }
};
