// @ts-nocheck
import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Field } from '../../types/transformationType';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import AddKPI from '../AddKPI/AddKPI';
import { ANALYSIS_FUNCTIONS } from '../../constants/file';
import { useSelector, useDispatch } from 'react-redux';
import {
  setExpandedAccordions,
  toggleAccordion,
} from '../../slices/accordionSlice';
import { updateFormField } from '../../slices/formFieldSlice';
import './AddReportingKPI.css';

const AddReportingKPI = (props: any) => {
  const {
    analyticsData,
    page,
    pageIndex,
    pageSection,
    pageSectionIndex,
    pageSectionId,
    additional,
  } = props;
  const dispatch = useDispatch();
  const pathToAnalysisGeneration = additional
    ? ['analytics', analyticsData, pageIndex, 'analysis_generation']
    : [
        'analytics',
        analyticsData,
        pageIndex,
        'page_sections',
        pageSectionIndex,
        'analysis_generation',
      ];
  const analysisGeneration = useSelector(
    (state) =>
      ['formField', ...pathToAnalysisGeneration].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );

  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );

  const [hoveredKPI, setHoveredKPI] = useState(-1);

  const AddKPIStep = (e: any, index: number = analysisGeneration.length) => {
    e.preventDefault();

    const updatedValues = structuredClone(analysisGeneration);

    updatedValues.splice(index, 0, {
      name: '',
      description: '',
      arguments: [],
      uid: crypto.randomUUID(),
    });

    dispatch(
      updateFormField({ path: pathToAnalysisGeneration, value: updatedValues }),
    );
  };

  const collapseAll = () => {
    const newArray = analysisGeneration.map((item) => item.uid);
    const filteredAccordions = expandedAccordions.filter(
      (accordion) => !newArray.includes(accordion),
    );

    dispatch(setExpandedAccordions(filteredAccordions));
  };

  const expandAll = () => {
    const newArray = [];
    analysisGeneration.forEach((item) => {
      if (!expandedAccordions.includes(item.uid)) {
        newArray.push(item.uid);
      }
    });
    dispatch(setExpandedAccordions([...expandedAccordions, ...newArray]));
  };

  const getStepIndex = (index) => {
    setHoveredKPI(index);
  };

  const accordionId = pageSection?.uid || page.uid;
  const isAccordionExpanded = expandedAccordions.includes(accordionId);

  return (
    <MuiAccordion
      id={accordionId}
      expanded={isAccordionExpanded}
      onChange={() => dispatch(toggleAccordion(accordionId))}
    >
      <MuiAccordionDetails>
        {/* add step at the beginning */}
        <div className="add-file-div">
          <div className="add-button-div">
            <p className="add-reporting-kpi-btn">Add Reporting KPI</p>
            <button
              className="add-file-button"
              onClick={(e) => AddKPIStep(e, 0)}
              type="button"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
          <Tooltip title="Collapse All" sx={{ margin: '0 10px' }}>
            <IconButton onClick={collapseAll}>
              <UnfoldLessIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Expand All">
            <IconButton onClick={expandAll}>
              <UnfoldMoreIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <div className="col-div">
            {analysisGeneration?.map((item: Field, index: number) => {
              return (
                <div
                  key={item.uid}
                  // get step index on hover
                  onMouseEnter={() => getStepIndex(index)}
                  onMouseLeave={() => getStepIndex(-1)}
                >
                  <MuiAccordion
                    id={item.uid}
                    key={item.uid}
                    onChange={() => dispatch(toggleAccordion(item.uid))}
                    expanded={expandedAccordions.includes(item.uid)}
                  >
                    <MuiAccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={<ArrowForwardIosSharpIcon />}
                    >
                      <Typography>
                        {ANALYSIS_FUNCTIONS[item.name] ||
                          item.name
                            .replace(/_/g, ' ')
                            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                              letter.toUpperCase(),
                            )}
                      </Typography>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <AddKPI
                        analyticsData={analyticsData}
                        colIndex={index}
                        page={page}
                        pageIndex={pageIndex}
                        pageSection={pageSection}
                        pageSectionIndex={pageSectionIndex}
                        pageSectionId={pageSectionId}
                        additional={additional}
                      />
                    </MuiAccordionDetails>
                  </MuiAccordion>
                  {/* Add step at index */}
                  <div
                    className={`step-add-button ${
                      index !== analysisGeneration?.length - 1 &&
                      hoveredKPI === index
                        ? ''
                        : 'hide-step-add-button'
                    } ${
                      index === analysisGeneration?.length - 1
                        ? 'last-step-add-button'
                        : ''
                    }`}
                  >
                    <button
                      className="add-file-button"
                      onClick={(e) => AddKPIStep(e, index + 1)}
                      type="button"
                    >
                      <AddCircleSharpIcon />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* add step at the end */}
        {analysisGeneration?.length ? (
          <div className="add-file-div">
            <button
              className="add-file-button"
              onClick={(e) => AddKPIStep(e)}
              type="button"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
        ) : (
          ''
        )}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default AddReportingKPI;
