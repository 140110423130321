// @ts-nocheck
import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { Page } from '../../types/analyticsType';
import ReportPage from '../ReportPage/ReportPage';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import { toggleAccordion } from '../../slices/accordionSlice';

import './ReportingTable.css';

const ReportingTable = ({ analyticsData }) => {
  const dispatch = useDispatch();
  const formFieldAnalyticsData = useSelector(
    (state) => state.formField.analytics[analyticsData] || [],
  );
  const expandedAccordions = useSelector(
    (state: any) => state.accordion.expandedAccordions,
  );
  // handle click event of the Add button
  const handleAddReportPageClick = (e: any) => {
    e.preventDefault();
    let tempAnalytics = structuredClone(formFieldAnalyticsData);

    tempAnalytics = [
      ...tempAnalytics,
      {
        uid: crypto.randomUUID(),
        output_page_number: tempAnalytics.length + 1,
        page_sections: [],
      },
    ];

    dispatch(
      updateFormField({
        path: ['analytics', analyticsData],
        value: tempAnalytics,
      }),
    );
  };

  return (
    <MuiAccordion
      id={analyticsData}
      expanded={expandedAccordions.includes(analyticsData)}
      onChange={() => dispatch(toggleAccordion(analyticsData))}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ArrowForwardIosSharpIcon />}
      >
        <Typography>
          <b>Main Reporting Table</b> (with a standardised data schema)
        </Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        <div
          style={{
            display: 'grid',
            gap: '20px',
            gridTemplateColumns: '5fr .5fr',
          }}
        >
          <div>
            <div className="data-source-type-inner-div">
              <div className="add-data-source-div">
                <p className="add-report-page-btn">Add a Report Page</p>
                <div className="btn-box acquirer-button-div">
                  <button
                    className="add-merchant-button"
                    onClick={(e) => handleAddReportPageClick(e)}
                  >
                    <AddCircleSharpIcon />
                  </button>
                </div>
              </div>
              {formFieldAnalyticsData?.map((page: Page, pageIndex: number) => {
                return (
                  <div key={page.uid}>
                    <ReportPage
                      analyticsData={analyticsData}
                      page={page}
                      pageIndex={pageIndex}
                      additional={false}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default ReportingTable;
