// @ts-nocheck
import React from 'react';
import { Argument } from '../../types/analyticsType';
import { getDataType, getArgumentInformations } from '../../utils/inputs';

import './AddKPIArgumentList.css';

import ItemBoolean from '../Form/ItemBoolean';
import ItemList from '../Form/ItemList';
import ItemDict from '../Form/ItemDict';
import ItemElse from '../Form/ItemElse';

const AddKPIArgumentList = (props: any) => {
  const {
    selectedStep,
    analyticsData,
    colIndex,
    page,
    pageIndex,
    pageSectionIndex,
    pageSectionId,
    additional,
  } = props;

  const contextHandleDataTypeChange = {
    dataType: 'analytics',
    fileIndex: pageIndex,
    colIndex,
    type: analyticsData, // analysis_output or other_output
    selectedStep: structuredClone(selectedStep),
    additional,
    page,
    pageSectionId,
    pageSectionIndex,
    analyticsData,
    pageIndex,
  };

  return (
    <div className="column-div">
      {selectedStep?.arguments?.map((item: Argument, index: number) => {
        const { isList, isDict, uid } = getArgumentInformations(item);
        return (
          <div key={uid} className="container">
            {getDataType(item.python_datatype) === 'boolean' ? (
              <ItemBoolean
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            ) : isList ? (
              <ItemList
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            ) : isDict ? (
              <ItemDict
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            ) : (
              <ItemElse
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            )}
            {['dict[str, str]', 'dict[str]', 'list[str]', 'list'].includes(
              item.type,
            ) && (
              <div style={{ fontSize: '10px', color: 'grey' }}>
                Add comma separated values
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AddKPIArgumentList;
