// @ts-nocheck
import TextField from '@mui/material/TextField';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import MultiEmail, { handleEmailChange } from '../MultiEmail/MultiEmail';
import { useSelector, useDispatch } from 'react-redux';
import { updateMerchantDataSources } from '../../slices/formFieldSlice';
import './AddMerchant.css';
import { preventEnterSubmit } from '../../utils/inputs';

const AddMerchant = () => {
  const dispatch = useDispatch();
  const merchantDataSources = useSelector(
    (state) => state.formField.merchant_data_sources,
  );

  // handle click event of the Remove button
  const handleRemoveClick = (e: any, index: any, item: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(merchantDataSources);
    updatedValues.splice(index, 1);
    dispatch(updateMerchantDataSources({ data: updatedValues }));
  };

  // handle click event of the Add button
  const handleAddClick = (e: any) => {
    e.preventDefault();
    dispatch(
      updateMerchantDataSources({
        data: [
          ...structuredClone(merchantDataSources),
          {
            merchant_name: '',
            merchant_email: [],
            uid: crypto.randomUUID(),
            data_source_type: [],
          },
        ],
      }),
    );
  };

  const handleValueChange = (index: any, e: any, fieldName: any, item: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(merchantDataSources);
    updatedValues[index][fieldName] = e.target.value;
    dispatch(updateMerchantDataSources({ data: updatedValues }));
  };

  return (
    <>
      {merchantDataSources?.map((item: any, i: any) => {
        return (
          <div key={item.uid}>
            <div className="merchant-div">
              <TextField
                required
                key={'merchant_name' + i}
                id="merchant_name"
                label="Merchant Name"
                variant="standard"
                value={item.merchant_name}
                onChange={(e: any) =>
                  handleValueChange(i, e, 'merchant_name', item)
                }
                onKeyDown={preventEnterSubmit}
              />
              <div>
                <MultiEmail
                  key={'merchant_name' + i}
                  id={`merchant_email`}
                  label="Main Merchant Contact Email(s) (not used for data requests)"
                  emailsArray={item.merchant_email}
                  handleOnChange={(_emails) => {
                    handleEmailChange(
                      i,
                      _emails,
                      'merchant_email',
                      merchantDataSources,
                      updateMerchantDataSources,
                      dispatch,
                    );
                  }}
                />
                <em>press enter to add new email</em>
              </div>
              <div className="btn-box merchant-button-div">
                {merchantDataSources.length !== 1 && (
                  <button
                    type="button"
                    className="remove-merchant-button"
                    onClick={(e) => handleRemoveClick(e, i, item)}
                  >
                    <RemoveCircleSharpIcon />
                  </button>
                )}
                <button
                  className="add-merchant-button"
                  onClick={(e: any) => handleAddClick(e)}
                >
                  <AddCircleSharpIcon />
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AddMerchant;
