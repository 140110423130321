import { createSlice } from '@reduxjs/toolkit';
import { DataType, OtherDataTypeState } from '../types/dataTypes';

const initialState: OtherDataTypeState = {
  data_types: [
    { value: 'other_events_data', name: 'Events Data' },
    { value: 'other_hotel_data', name: 'Hotel Data' },
    { value: 'other_travel_data', name: 'Travel Data' },
    { value: 'other_weather_data_rainfall', name: 'Weather (Rainfall) Data' },
    {
      value: 'other_weather_data_temperature',
      name: 'Weather (Temperature) Data',
    },
  ],
};

export const otherDataTypesSlice = createSlice({
  name: 'otherDataTypes',
  initialState,
  reducers: {
    addNewDataType: (state, action) => {
      state.data_types = state.data_types = state.data_types.some(
        (dataType: DataType) => dataType.value === action.payload.value,
      )
        ? [...state.data_types]
        : [...state.data_types, action.payload];
    },
  },
});

export const { addNewDataType } = otherDataTypesSlice.actions;

export default otherDataTypesSlice.reducer;
