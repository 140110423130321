import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DATA_TYPE } from '../../constants/file';

import './DataTable.css';

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row">{row.file_name}</TableCell>
        <TableCell align="right">{row.file_type}</TableCell>
        <TableCell align="right">{DATA_TYPE[row.data_type]}</TableCell>
        <TableCell align="right">
          {row.merchant_name.join(', ') || 'NA'}
        </TableCell>
        <TableCell align="right">
          {row.acquirer_name.join(', ') || 'NA'}
        </TableCell>
        <TableCell align="right">{row.col_name.join(', ') || 'NA'}</TableCell>
        <TableCell align="right">{row.priority}</TableCell>
      </TableRow>
      <TableRow sx={{ border: '1px solid lightgray !important' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginLeft: '50px' }}>
              {row?.transformation?.length > 0 ? (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Trans Function Name</TableCell>
                      <TableCell>Argument List</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.transformation.map((historyRow: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell>{historyRow.name}</TableCell>
                        {historyRow &&
                          historyRow.arguments &&
                          historyRow.arguments.map(
                            (internalRow: any, index: any) => (
                              <TableCell key={index}>
                                {Object.keys(internalRow)[0]}(
                                {internalRow[Object.keys(internalRow)[0]]})
                              </TableCell>
                            ),
                          )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <p>No transformation selected</p>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow sx={{ border: '1px solid lightgray !important' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginLeft: '50px' }}>
              {row.validation && row.validation.length > 0 ? (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Valid Function Name</TableCell>
                      <TableCell>Argument List</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.validation &&
                      row.validation.map((historyRow: any, index: any) => (
                        <TableRow key={index}>
                          <TableCell className="fixedWidth">
                            {historyRow.name}
                          </TableCell>
                          {historyRow &&
                            historyRow.arguments.map(
                              (internalRow: any, index: any) => (
                                <TableCell key={index}>
                                  {Object.keys(internalRow)[0]}(
                                  {internalRow[Object.keys(internalRow)[0]]})
                                </TableCell>
                              ),
                            )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <p>No validation selected</p>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function DataTable(props: any) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        {props.data && props.data.length > 0 && (
          <TableHead>
            <TableRow>
              <TableCell width="40" />
              <TableCell>Expected File Name</TableCell>
              <TableCell align="right">File Type</TableCell>
              <TableCell align="right">Data Type</TableCell>
              <TableCell align="right">Merchant</TableCell>
              <TableCell align="right">Acquirer</TableCell>
              <TableCell align="right">Col Name</TableCell>
              <TableCell align="right">Priority</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {props.data &&
            props.data.map((row: any, index: any) => (
              <Row key={index} row={row} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
