import * as React from 'react';
import { Container } from '@mui/material';
import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';

const Alerts = () => {
  const alerts = useSelector((state) => state.alerts.alerts);

  return (
    <>
      {alerts.length > 0 && (
        <Container maxWidth="xl">
          {alerts.map((alert) => (
            <Alert
              key={alert.id}
              severity={alert.type}
              sx={{ justifyContent: 'center' }}
            >
              {alert.message}
            </Alert>
          ))}
        </Container>
      )}
    </>
  );
};
export default Alerts;
