import './Footer.css';
const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="app-footer">
      &copy; {currentYear} PricewaterhouseCoopers LLP. All rights reserved. In
      this document, 'PwC' refers to the UK member firm, and may sometimes refer
      to the PwC network. Each member firm is a separate legal entity. Please
      see{' '}
      <a href="https://www.pwc.com/structure" target="_blank" rel="noreferrer">
        www.pwc.com/structure
      </a>{' '}
      for further details.
    </div>
  );
};

export default Footer;
