// @ts-nocheck
import { useState, useEffect } from 'react';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import AddKPIArgumentList from '../AddKPIArgumentList/AddKPIArgumentList';
import { Field } from '../../types/analyticsType';
import { ANALYSIS_FUNCTIONS } from '../../constants/file';
import { prepareArgument } from '../../utils/inputs';
import './AddKPI.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';

const AddKPI = ({
  analyticsData,
  colIndex,
  page,
  pageIndex,
  pageSection,
  pageSectionIndex,
  pageSectionId,
  additional,
}: any) => {
  const dispatch = useDispatch();
  const pathToAnalysisGeneration = additional
    ? ['analytics', analyticsData, pageIndex, 'analysis_generation']
    : [
        'analytics',
        analyticsData,
        pageIndex,
        'page_sections',
        pageSectionIndex,
        'analysis_generation',
      ];
  const analysisGeneration = useSelector(
    (state) =>
      ['formField', ...pathToAnalysisGeneration].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );
  const [stepFields, setStepFields] = useState<Field[]>([]);

  const analyticsSelector = useSelector(
    (state: RootState) => state.analytics.analytics,
  );

  const analyticsFieldsSelector = useSelector(
    (state: RootState) => state.analytics.analyticsFields,
  );

  useEffect(() => {
    const myClonedArray = [];
    // todotodo
    analyticsFieldsSelector?.forEach((val) =>
      myClonedArray.push(Object.assign({}, val)),
    );
    analyticsFieldsSelector.length > 0 && setStepFields(myClonedArray);
  }, [analyticsSelector, analyticsFieldsSelector]);

  const handleRemoveClick = (e: any, index: number) => {
    e.preventDefault();
    const updatedValues = structuredClone(analysisGeneration);

    updatedValues.splice(index, 1);
    dispatch(
      updateFormField({ path: pathToAnalysisGeneration, value: updatedValues }),
    );
  };

  const handleSelectedStep = (e: any) => {
    e.preventDefault();
    const selectedStepTemp = structuredClone(
      stepFields?.find((item) => item.name === e.target.value),
    );

    const selectedStepArguments = selectedStepTemp?.arguments?.map((item) =>
      prepareArgument(item),
    );

    selectedStepTemp.arguments = [...selectedStepArguments];
    const updatedValues = structuredClone(analysisGeneration);

    updatedValues[colIndex] = {
      ...selectedStepTemp,
      uid: updatedValues[colIndex].uid
        ? updatedValues[colIndex].uid
        : crypto.randomUUID(),
    };
    dispatch(
      updateFormField({ path: pathToAnalysisGeneration, value: updatedValues }),
    );
  };

  const sortAnalysisFunctionItems = (array: []) => {
    array.sort((a, b) => {
      if (!ANALYSIS_FUNCTIONS[a.name]) {
        return a.name
          .replace(/_/g, ' ')
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
          .localeCompare(ANALYSIS_FUNCTIONS[b.name]);
      } else if (!ANALYSIS_FUNCTIONS[b.name]) {
        return ANALYSIS_FUNCTIONS[a.name].localeCompare(
          b.name
            .replace(/_/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        );
      } else if (!ANALYSIS_FUNCTIONS[a.name] && !ANALYSIS_FUNCTIONS[b.name]) {
        return a.name
          .replace(/_/g, ' ')
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
          .localeCompare(
            b.name
              .replace(/_/g, ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
          );
      } else {
        return ANALYSIS_FUNCTIONS[a.name].localeCompare(
          ANALYSIS_FUNCTIONS[b.name],
        );
      }
    });

    return array;
  };

  return (
    <div className="column-analytics-div">
      <div className="row-div">
        <FormControl required fullWidth>
          <InputLabel id="demo-simple-select-label1">Select KPI</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label1"
            id="demo-simple-select1"
            value={analysisGeneration?.[colIndex]?.name || ''}
            label="Select a analytics"
            name="analytics"
            defaultValue="rename_column"
            variant="standard"
            onChange={handleSelectedStep}
          >
            {stepFields.length > 0 &&
              sortAnalysisFunctionItems(stepFields).map(
                (item: Field, index: number) => {
                  return (
                    <MenuItem key={index} value={item.name}>
                      {ANALYSIS_FUNCTIONS[item.name] ||
                        item.name
                          .replace(/_/g, ' ')
                          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                            letter.toUpperCase(),
                          )}
                    </MenuItem>
                  );
                },
              )}
          </Select>
          <FormHelperText className="helper-text">
            {analysisGeneration?.[colIndex]?.description || ''}
          </FormHelperText>
        </FormControl>
        <div>
          <button
            type="button"
            className="remove-merchant-button"
            onClick={(e) => handleRemoveClick(e, colIndex)}
          >
            <RemoveCircleSharpIcon />
          </button>
        </div>
      </div>
      <AddKPIArgumentList
        selectedStep={structuredClone(analysisGeneration?.[colIndex])}
        analyticsData={analyticsData}
        colName={analysisGeneration?.[colIndex]?.name || ''}
        colIndex={colIndex}
        page={page}
        pageIndex={pageIndex}
        pageSection={pageSection}
        pageSectionIndex={pageSectionIndex}
        pageSectionId={pageSectionId}
        additional={additional}
      />
    </div>
  );
};

export default AddKPI;
