import { TableContainer } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { ReactNode } from 'react';
import Box from '@mui/material/Box';

const h2FontSize = 32;
const h2MB = 4;
const parameterFontFamily = 'Courier New';

const rows = [
  {
    first: 'MFT4U landing zone clean-up',
    second:
      'At 9am on the last weekday (Mon-Fri) before the first weekday of the reporting period',
    third:
      'Never',
  },
  {
    first: 'Data request emails',
    second: 'At 9am of the first weekday of the reporting period',
    third: 'Never',
  },
  {
    first: 'Chaser emails',
    second: (
      <>
        At 9am
        <ul>
          <li>on the first weekday on or after the Ingestion Start Date</li>
          <li>on the Ingestion End Date unless it’s a weekend</li>
          <li>every Monday and every Wednesday between the above two dates</li>
        </ul>
      </>
    ),
    third: 'Never',
  },
  {
    first: 'Data ingestion',
    second:
      'Every weekday from the Ingestion Start Date to the Ingestion End Date at the time specified for the Ingestion Start Date',
    third: 'Never',
  },
  {
    first: 'Data transformation and validation',
    second:
      'Six hours after the data ingestion pipeline has began running',
    third: 'Never',
  },
  {
    first: 'Analysis and reporting',
    second: 'On the Data Analysis and Reporting Date and Time',
    third: 'Never',
  },
] as const satisfies ReadonlyArray<{
  first: ReactNode;
  second: ReactNode;
  third: ReactNode;
}>;

const ProductUserGuide = () => (
  <>
    <Typography variant="h1" fontSize={48} mb={6}>
      Places Insights / Customer Insights product user guide
    </Typography>
    <Typography variant="h2" fontSize={h2FontSize} mb={h2MB}>
      Processes and Dates
    </Typography>
    <Typography mb={1}>
      Engagement Start Date and Engagement End Date: will not impact data
      pipelines.
    </Typography>
    <Typography mb={4}>
      First Reporting Period Start Date, First Reporting Period End Date and
      Reporting Cycle will determine the reporting period.
    </Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Process</TableCell>
            <TableCell>
              When it is run when ‘Will the pipeline be run multiple times’ is
              set as Yes?
            </TableCell>
            <TableCell>
              When it is run when ‘Will the pipeline be run multiple times’ is
              set as No?
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ first, second, third }) => (
            <TableRow key={first}>
              <TableCell>{first}</TableCell>
              <TableCell>{second}</TableCell>
              <TableCell>{third}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Typography variant="h2" fontSize={h2FontSize} my={h2MB}>
      Ingestion, Transformations and Validations
    </Typography>
    <Box component="ul" mb={4}>
      <Box component="li" mb={4}>
        When files of transaction or other data types are ingested, the
        following columns are added based on user input at a File level in the
        WebApp:{' '}
        <Box component="span" fontFamily={parameterFontFamily}>
          _merchant
        </Box>
        ,{' '}
        <Box component="span" fontFamily={parameterFontFamily}>
          _acquirer
        </Box>
        ,{' '}
        <Box component="span" fontFamily={parameterFontFamily}>
          _location
        </Box>
        . Those fields may be needed when entering transformations and
        validations in the WebApp:
        <ul>
          <li>
            Values in{' '}
            <Box component="span" fontFamily={parameterFontFamily}>
              _location
            </Box>{' '}
            are not used, so can be ignored.
          </li>
          <li>
            For data types other than transaction, columns{' '}
            <Box component="span" fontFamily={parameterFontFamily}>
              _merchant
            </Box>
            ,{' '}
            <Box component="span" fontFamily={parameterFontFamily}>
              _acquirer
            </Box>
            ,{' '}
            <Box component="span" fontFamily={parameterFontFamily}>
              _location
            </Box>{' '}
            are populated but remain empty and can be ignored.
          </li>
        </ul>
      </Box>
      <li>
        During the ingestion process, for all data sources, spaces in column
        names are replaced with underscores. E.g. column “
        <Box component="span" fontFamily={parameterFontFamily}>
          abc efg
        </Box>
        ” will be imported to the bronze layer as “
        <Box component="span" fontFamily={parameterFontFamily}>
          abc_efg
        </Box>
        ”. The user should keep this in mind when entering parameters for
        transformations and validations in the WebApp (e.g., use{' '}
        <Box component="span" fontFamily={parameterFontFamily}>
          source_column=abc_efg
        </Box>{' '}
        when the column in the raw file is “
        <Box component="span" fontFamily={parameterFontFamily}>
          abc efg
        </Box>
        ”).
      </li>
    </Box>
  </>
);

export default ProductUserGuide;
