// @ts-nocheck
import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateEngagementDetails } from '../../slices/formFieldSlice';
import './EmailTemplate.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EmailTemplate = (props: any) => {
  const { title, dataKey } = props;
  const dispatch = useDispatch();
  const engagementDetails = useSelector(
    (state) => state.formField.engagement_details,
  );
  const { Text } = Typography;

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  useEffect(() => {
    setEditorState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            engagementDetails[dataKey]
              .replaceAll(
                `<a href='{{ upload_url }}'>link</a>`,
                '{{ upload_link }}',
              )
              .replaceAll('{{', '@{{'),
          ),
        ),
      ),
    );
  }, []);

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);

    const html = stateToHTML(editorState.getCurrentContent());

    dispatch(
      updateEngagementDetails({
        data: {
          ...structuredClone(engagementDetails),
          [dataKey]:
            html === '<p><br></p>'
              ? ''
              : html
                  .replaceAll('@', '')
                  .replaceAll('&nbsp;', '')
                  .replaceAll('&lt;', '<')
                  .replaceAll('&gt;', '>')
                  .replaceAll(
                    '{{ upload_link }}',
                    `<a href='{{ upload_url }}'>link</a>`,
                  ),
        },
      }),
    );
  };

  return (
    <div>
      <Typography className="email-template-title">{title}</Typography>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        placeholder="Enter email template..."
        toolbar={{
          options: ['inline', 'list', 'textAlign'],
          inline: {
            options: [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'superscript',
              'subscript',
            ],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
        }}
        mention={{
          separator: ' ',
          trigger: '@',
          suggestions: [
            {
              text: 'merchant_list',
              value: '{{ merchant_list }}',
              url: 'merchant_list',
            },
            {
              text: 'acquirer_list',
              value: '{{ acquirer_list }}',
              url: 'acquirer_list',
            },
            {
              text: 'deadline_date',
              value: '{{ data_deadline_date }}',
              url: 'data_deadline_date',
            },
            {
              text: 'reporting_period',
              value: '{{ reporting_period }}',
              url: 'reporting_period',
            },
            {
              text: 'upload_link',
              value: '{{ upload_link }}',
              url: 'upload_link',
            },
          ],
        }}
      />
      <Text className="email-template-note" type="secondary">
        *Please use @ to add a parameter
      </Text>
    </div>
  );
};

export default EmailTemplate;
