import * as React from 'react';
import { useState, useEffect } from 'react';
import config from './config';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import PageRoutes from './routes/PageRoutes';
import Alerts from './components/Alerts/Alerts';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookies from 'js-cookie';
import {
  CheckAuthentication,
  exchangeCodeForToken,
} from './services/authentication.service';
import { useUrlSearchParams } from 'use-url-search-params';
import { useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import {
  setCookieWithEncryption,
  getCookieWithDecryption,
} from './utils/crypto';
// import { controller } from './services/http';

import './App.css';

function App() {
  const [loggedUser, setLoggedUser] = useState<string>('');
  const [access, setAccess] = useState(false);
  const [params, setParams] = useUrlSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const auth = getCookieWithDecryption('access');
  if (params.code === undefined && (auth === null || auth !== true)) {
    if (!access) {
      try {
        CheckAuthentication();
      } catch (error: any) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    }
  }

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#d04a02',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#cb1130',
      },
    },
  });

  useEffect(() => {
    const auth = getCookieWithDecryption('access');
    if (params.code && (auth == null || auth !== true)) {
      setCookieWithEncryption('access', false, {
        expires: 1, // Will expire after 24 hours (value is in number of sec.)
      });
      const verifier = getCookieWithDecryption('codeVerifier') as string;
      try {
        exchangeCodeForToken(params.code.toString(), verifier ? verifier : '');
      } catch (error: any) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    }

    // return () => controller.abort();
  }, []);
  // }, [params.code]);

  const clearQueryParams = () => {
    searchParams.delete('code');
    searchParams.delete('iss');
    searchParams.delete('client_id');
    setSearchParams(searchParams);
  };

  const check = setInterval(function () {
    const res = getCookieWithDecryption('access');
    if (res === true) {
      setAccess(true);
      clearQueryParams();
      clearInterval(check);
      return;
    } else {
      setAccess(false);
    }
  }, 100);
  if (access) {
    clearInterval(check);
  }
  useEffect(() => {
    const res = getCookieWithDecryption('access');
    if (res === true) {
      setAccess(true);
    }
  }, []);
  setTimeout(
    function () {
      Cookies.remove('access');
    },
    1000 * 60 * 60 * 10,
  ); // controls the interval for re authentication

  return (
    <>
      {access ? (
        <>
          <ThemeProvider theme={theme}>
            <Header loggedUser={loggedUser} />
            <Alerts />
            <Container fixed>
              <Box sx={{ height: 'auto', margin: '10px', minHeight: '575px' }}>
                <PageRoutes />
              </Box>
            </Container>
            <Footer />
          </ThemeProvider>
        </>
      ) : (
        <>
          <ThemeProvider theme={theme}>
            <Header />
            <Container fixed>
              <Box sx={{ height: 'auto', margin: '10px', minHeight: '575px' }}>
                <div className="loading">
                  <PropagateLoader color="black" />
                </div>
                <br></br>

                <div className="loading">
                  Checking your permissions. Try to&nbsp;
                  <a href={config.redirectionUrl}> refresh </a>&nbsp;or contact
                  Administrator (ashwin.mathur@pwc.com) if the page does not
                  redirect.
                </div>
              </Box>
            </Container>
            <Footer />
          </ThemeProvider>
        </>
      )}
    </>
  );
}

export default App;
