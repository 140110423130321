// @ts-nocheck
import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import AddReportingKPI from '../AddReportingKPI/AddReportingKPI';
import { PageSection } from '../../types/analyticsType';
import { TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import { toggleAccordion } from '../../slices/accordionSlice';
import { preventEnterSubmit } from '../../utils/inputs';
import './AddPageSection.css';

const AddPageSection = (props: any) => {
  const {
    analyticsData,
    page,
    pageIndex,
    pageSection,
    pageSectionIndex,
    additional,
  } = props;
  const dispatch = useDispatch();
  const pathToAnalysisPageSections = [
    'analytics',
    analyticsData,
    pageIndex,
    'page_sections',
  ];
  const analysisPageSections = useSelector(
    (state) =>
      ['formField', ...pathToAnalysisPageSections].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || [],
  );
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );

  const handleRemoveClick = (e: any, index: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(analysisPageSections);

    updatedValues.splice(index, 1);

    updatedValues.map((section: PageSection, sectionIndex: number) => {
      section.output_table_id = `${pageIndex + 1}${String.fromCharCode(
        sectionIndex + 65,
      )}`;
    });

    dispatch(
      updateFormField({
        path: pathToAnalysisPageSections,
        value: updatedValues,
      }),
    );
  };

  const handleValueChange = (e: any) => {
    e.preventDefault();
    let tempPageSectionOutputTableName = structuredClone(
      analysisPageSections[pageSectionIndex].output_table_name,
    );

    tempPageSectionOutputTableName = e.target.value;

    dispatch(
      updateFormField({
        path: [
          ...pathToAnalysisPageSections,
          pageSectionIndex,
          'output_table_name',
        ],
        value: tempPageSectionOutputTableName,
      }),
    );
  };

  return (
    <MuiAccordion
      id={pageSection.uid}
      expanded={expandedAccordions.includes(pageSection.uid)}
      onChange={() => dispatch(toggleAccordion(pageSection.uid))}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ArrowForwardIosSharpIcon />}
      >
        <Typography>{pageSection?.output_table_id}</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        <div className="section-input-container">
          <TextField
            required
            label="Section Name"
            variant="standard"
            value={pageSection.output_table_name}
            onChange={handleValueChange}
            sx={{ marginBottom: '10px' }}
            onKeyDown={preventEnterSubmit}
          />
          <div>
            <button
              type="button"
              className="remove-merchant-button"
              onClick={(e) => handleRemoveClick(e, pageSectionIndex)}
            >
              <RemoveCircleSharpIcon />
            </button>
          </div>
        </div>
        <AddReportingKPI
          analyticsData={analyticsData}
          page={page}
          pageIndex={pageIndex}
          pageSection={pageSection}
          pageSectionIndex={pageSectionIndex}
          pageSectionId={pageSection.output_table_id}
          additional={additional}
        />
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default AddPageSection;
