// @ts-nocheck
import { useState, useEffect } from 'react';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import AddFinalArgumentList from '../AddFinalArgumentList/AddFinalArgumentList';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from '../../types/transformationType';
import { TRANSFORMATIONS, VALIDATIONS } from '../../constants/file';
import { prepareArgument } from '../../utils/inputs';
import { updateFormField } from '../../slices/formFieldSlice';
import './AddFinalStep.css';

const AddFinalStep = (props: any) => {
  const { colIndex, finalItem, list } = props;
  const dispatch = useDispatch();
  const [stepFields, setStepFields] = useState<Field[]>([]);

  const formFieldList = useSelector((state) => state.formField[list]);
  const transformationsSelector = useSelector(
    (state: RootState) => state.transformations.transformations,
  );

  const transformationFieldsSelector = useSelector(
    (state: RootState) => state.transformations.transformationsFields,
  );

  const validationsSelector = useSelector(
    (state: RootState) => state.validations.validations,
  );

  const validationFieldsSelector = useSelector(
    (state: RootState) => state.validations.validationsFields,
  );

  useEffect(() => {
    const myClonedArray = [];
    if (list === 'final_transformation') {
      transformationFieldsSelector?.forEach((val) =>
        myClonedArray.push(Object.assign({}, val)),
      );
      transformationFieldsSelector.length > 0 && setStepFields(myClonedArray);
    }
  }, [transformationsSelector, transformationFieldsSelector, list]);

  useEffect(() => {
    const myClonedArray = [];
    if (list === 'final_validation') {
      validationFieldsSelector?.forEach((val) =>
        myClonedArray.push(Object.assign({}, val)),
      );
      validationFieldsSelector.length > 0 && setStepFields(myClonedArray);
    }
  }, [validationsSelector, validationFieldsSelector, list]);

  const handleRemoveClick = (e: any, index: number) => {
    e.preventDefault();
    const updatedValues = structuredClone(formFieldList);
    updatedValues[finalItem.name].splice(index, 1);

    dispatch(
      updateFormField({
        path: [list, finalItem.name],
        value: updatedValues[finalItem.name],
      }),
    );
  };

  const handleSelectedStep = (e: any) => {
    e.preventDefault();
    const selectedStepTemp = structuredClone(
      stepFields?.find((item) => item.name === e.target.value),
    );

    const selectedStepArguments =
      selectedStepTemp.arguments &&
      selectedStepTemp.arguments.map((item) => prepareArgument(item));

    selectedStepTemp.arguments = [...selectedStepArguments];

    const updatedValues = structuredClone(formFieldList);
    updatedValues[finalItem.name][colIndex] = {
      ...selectedStepTemp,

      uid: updatedValues[finalItem.name][colIndex].uid
        ? updatedValues[finalItem.name][colIndex].uid
        : crypto.randomUUID(),
    };

    dispatch(
      updateFormField({
        path: [list, finalItem.name],
        value: updatedValues[finalItem.name],
      }),
    );
  };

  const sortFinalItems = (array: []) => {
    array.sort((a, b) => {
      if (list === 'final_transformation') {
        if (!TRANSFORMATIONS[a.name]) {
          return a.name
            .replace(/_/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
            .localeCompare(TRANSFORMATIONS[b.name]);
        } else if (!TRANSFORMATIONS[b.name]) {
          return TRANSFORMATIONS[a.name].localeCompare(
            b.name
              .replace(/_/g, ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
          );
        } else if (!TRANSFORMATIONS[a.name] && !TRANSFORMATIONS[b.name]) {
          return a.name
            .replace(/_/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
            .localeCompare(
              b.name
                .replace(/_/g, ' ')
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase(),
                ),
            );
        } else {
          return TRANSFORMATIONS[a.name].localeCompare(TRANSFORMATIONS[b.name]);
        }
      } else if (list === 'final_validation') {
        if (!VALIDATIONS[a.name]) {
          return a.name
            .replace(/_/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
            .localeCompare(VALIDATIONS[b.name]);
        } else if (!VALIDATIONS[b.name]) {
          return VALIDATIONS[a.name].localeCompare(
            b.name
              .replace(/_/g, ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
          );
        } else if (!VALIDATIONS[a.name] && !VALIDATIONS[b.name]) {
          return a.name
            .replace(/_/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
            .localeCompare(
              b.name
                .replace(/_/g, ' ')
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase(),
                ),
            );
        } else {
          return VALIDATIONS[a.name].localeCompare(VALIDATIONS[b.name]);
        }
      }

      return 0;
    });

    return array;
  };

  return (
    <div className="column-transformation-div">
      <div className="row-div">
        <FormControl required fullWidth>
          <InputLabel id="demo-simple-select-label1">
            {list === 'final_transformation'
              ? 'Select a transformation'
              : 'Select a validation'}
          </InputLabel>
          <Select
            required
            labelId="demo-simple-select-label1"
            id="demo-simple-select1"
            value={formFieldList?.[finalItem.name]?.[colIndex]?.name || ''}
            label="Select a transformation"
            name="list"
            defaultValue="rename_column"
            variant="standard"
            onChange={handleSelectedStep}
          >
            {stepFields.length > 0 &&
              sortFinalItems(stepFields).map((item: Field, index: number) => {
                return (
                  <MenuItem key={index} value={item.name}>
                    {TRANSFORMATIONS[item.name] ||
                      VALIDATIONS[item.name] ||
                      item.name
                        .replace(/_/g, ' ')
                        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                          letter.toUpperCase(),
                        )}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formFieldList?.[finalItem.name]?.[colIndex]?.description || ''}
          </FormHelperText>
        </FormControl>
        <div>
          <button
            type="button"
            className="remove-merchant-button"
            onClick={(e) => handleRemoveClick(e, colIndex)}
          >
            <RemoveCircleSharpIcon />
          </button>
        </div>
      </div>
      <AddFinalArgumentList
        selectedStep={formFieldList?.[finalItem.name]?.[colIndex]}
        list={list}
        finalItem={finalItem}
        colIndex={colIndex}
      />
    </div>
  );
};

export default AddFinalStep;
