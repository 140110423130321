// @ts-nocheck
import { useState, useEffect, StrictMode } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FinalAccordion from '../FinalAccordion/FinalAccordion';
import { FinalStep } from '../../types/finalStepType';
import { addDataType, DATA_TYPE } from '../../constants/file';
import { useSelector, useDispatch } from 'react-redux';
import { updateFinalData, updateFormFields } from '../../slices/formFieldSlice';
import { toggleAccordion } from '../../slices/accordionSlice';

import './FinalTransformationsAndValidations.css';

const FinalTransformationsAndValidations = () => {
  const [selectedDataTypes, setSelectedDataTypes] = useState<string[]>([]);
  const [dataTypeNames, setDataTypeNames] = useState(DATA_TYPE);
  const dispatch = useDispatch();
  const finalTransformation = useSelector(
    (state) => state.formField.final_transformation,
  );
  const finalValidation = useSelector(
    (state) => state.formField.final_validation,
  );
  const finalData = useSelector((state) => state.formField.final_data);
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );

  useEffect(() => {
    finalData?.forEach((fileItem) => {
      const { name } = fileItem || {};

      if (
        name &&
        !DATA_TYPE[name] &&
        (name.startsWith('other_') || name.startsWith('lookup_'))
      ) {
        const formattedName = name
          .substring(name.indexOf('_') + 1)
          .replace(/_/g, ' ')
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

        addDataType(name, formattedName);
      }
    });
    setDataTypeNames(DATA_TYPE);
  }, [finalData]);

  useEffect(() => {
    const tempFinalData = [] as any[];
    //finalData
    Object.keys(finalTransformation)?.forEach((key, index) => {
      tempFinalData.push({
        name: key,
        final_transformations: structuredClone(finalTransformation[key]),
        uid: finalData?.[index]?.uid
          ? finalData?.[index]?.uid
          : crypto.randomUUID(),
      });
    });

    const updatedFinalData = tempFinalData.map((item) => {
      const validation = finalValidation[item.name];
      if (validation) {
        return {
          ...item,
          final_validations: structuredClone(validation),
        };
      }
      return item;
    });

    dispatch(updateFinalData({ data: updatedFinalData }));
  }, [finalTransformation, finalValidation, dispatch]);

  const handleInputChange = (e: SelectChangeEvent, index: number, uid) => {
    e.preventDefault();
    const dataType = e.target.value;

    const tempFinalData = structuredClone(finalData) as FinalStep[];

    let finalDataTransformation = {};
    let finalDataValidation = {};

    if (!selectedDataTypes.find((type) => type === dataType)) {
      setSelectedDataTypes([...structuredClone(selectedDataTypes), dataType]);
    }

    tempFinalData[index].name = dataType;
    tempFinalData.map((item) => {
      if (item.name) {
        finalDataTransformation = {
          ...finalDataTransformation,
          [item.name]: finalTransformation[item.name]?.length
            ? structuredClone(finalTransformation[item.name])
            : [],
        };

        finalDataValidation = {
          ...finalDataValidation,
          [item.name]: finalValidation[item.name]?.length
            ? structuredClone(finalValidation[item.name])
            : [],
        };
      }
      return { finalDataTransformation, finalDataValidation };
    });

    dispatch(
      updateFormFields({
        keys: ['final_transformation', 'final_validation'],
        values: [finalDataTransformation, finalDataValidation],
      }),
    );
  };

  // handle click event of the Remove button
  const handleRemoveFinalTransformationsAndValidationsClick = (
    e: any,
    index: number,
    item: FinalStep,
  ) => {
    e.preventDefault();
    const updatedValues = structuredClone(finalData);
    updatedValues.splice(index, 1);

    const updatedTransformation = structuredClone(finalTransformation);
    delete updatedTransformation[item.name];

    const updatedValidation = structuredClone(finalValidation);
    delete updatedValidation[item.name];

    dispatch(
      updateFormFields({
        keys: ['final_data', 'final_transformation', 'final_validation'],
        values: [updatedValues, updatedTransformation, updatedValidation],
      }),
    );

    setSelectedDataTypes(
      selectedDataTypes.filter((dataType) => dataType !== item.name),
    );
  };

  // handle click event of the Add button
  const handleAddDataTypeClick = (e: any) => {
    e.preventDefault();

    dispatch(
      updateFinalData({
        data: [
          ...structuredClone(finalData),
          {
            name: '',
            final_transformations: [],
            final_validations: [],
            uid: crypto.randomUUID(),
          },
        ],
      }),
    );
  };

  return (
    <>
      <div className="add-data-source-div">
        <p style={{ fontWeight: 'bold' }}>Add Data Type</p>
        <div className="btn-box acquirer-button-div">
          <button
            className="add-merchant-button"
            onClick={(e) => handleAddDataTypeClick(e)}
          >
            <AddCircleSharpIcon />
          </button>
        </div>
      </div>
      {finalData?.map((finalItem: FinalStep, index: number) => {
        return (
          <MuiAccordion
            id={finalItem.uid}
            expanded={expandedAccordions.includes(finalItem.uid)}
            onChange={() => dispatch(toggleAccordion(finalItem.uid))}
            key={finalItem.uid}
          >
            <MuiAccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<ArrowForwardIosSharpIcon />}
            >
              <Typography>
                {finalItem.name
                  ? dataTypeNames[finalItem.name]
                  : 'Undefined Data Type'}
              </Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <div
                style={{
                  display: 'grid',
                  gap: '20px',
                  gridTemplateColumns: '5fr .5fr',
                }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      flexDirection: 'column',
                    }}
                    className="data-source-type-inner-div"
                  >
                    <div className="data-source-type-div">
                      <div className="data-source-type-inner-div">
                        <FormControl required fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Data Type
                          </InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={finalItem.name}
                            label="Data Source Type"
                            onChange={(e: SelectChangeEvent) => {
                              handleInputChange(e, index, finalItem.uid);
                            }}
                          >
                            {Object.keys(dataTypeNames).map(
                              (type, typeIndex) => (
                                <MenuItem
                                  value={type}
                                  key={typeIndex}
                                  disabled={finalData?.some(
                                    (finalDataItem: FinalStep) =>
                                      finalDataItem.name === type,
                                  )}
                                >
                                  {dataTypeNames[type]}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                        <div>
                          <FinalAccordion
                            finalItem={finalItem}
                            finalItemIndex={index}
                            list="final_transformation"
                            uid={finalItem.uid}
                          />
                          <FinalAccordion
                            finalItem={finalItem}
                            finalItemIndex={index}
                            list="final_validation"
                            uid={finalItem.uid}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {finalData?.length !== 1 && (
                    <button
                      type="button"
                      className="remove-merchant-button"
                      onClick={(e) =>
                        handleRemoveFinalTransformationsAndValidationsClick(
                          e,
                          index,
                          finalItem,
                        )
                      }
                    >
                      <RemoveCircleSharpIcon />
                    </button>
                  )}
                </div>
              </div>
            </MuiAccordionDetails>
          </MuiAccordion>
        );
      })}
    </>
  );
};

export default FinalTransformationsAndValidations;
