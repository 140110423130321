// @ts-nocheck
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import ApiDataSource from '../../components/ApiDataSource/ApiDataSource';
import AzureDataSource from '../../components/AzureDataSource/AzureDataSource';
import MftDataSource from '../../components/MftDataSource/MftDataSource';
import { useSelector, useDispatch } from 'react-redux';
import { updateEngagementTeamDataSources } from '../../slices/formFieldSlice';

import './AddEngagementDataSource.css';

const AddEngagementDataSource = (props: any) => {
  const dispatch = useDispatch();
  const engagementTeamDataSources = useSelector(
    (state) => state.formField.engagement_team_data_sources,
  );

  const handleInputChange = (
    e: SelectChangeEvent,
    index: any,
    item: any,
    j: any,
  ) => {
    e.preventDefault();
    const updatedValues = structuredClone(engagementTeamDataSources);
    updatedValues[index].data_source_type[j].selected_type = e.target.value;
    if (e.target.value === 'Api') {
      updatedValues[index].data_source_type[j].url = '';
      updatedValues[index].data_source_type[j].user_name = '';
      updatedValues[index].data_source_type[j].key = '';
      updatedValues[index].data_source_type[j].password = '';
      updatedValues[index].data_source_type[j].ingestion_start_date = '';
      updatedValues[index].data_source_type[j].ingestion_end_date = '';
    } else if (e.target.value === 'azure') {
      updatedValues[index].data_source_type[j].url = '';
      updatedValues[index].data_source_type[j].sas_token = '';
    } else {
      updatedValues[index].data_source_type[j].email = [];
      updatedValues[index].data_source_type[j].ingestion_start_date = '';
      updatedValues[index].data_source_type[j].ingestion_end_date = '';
    }
    updatedValues[index].data_source_type[j].file = [];
    updatedValues[index].data_source_type[j].file_count =
      updatedValues[index].data_source_type[j].file.length;

    dispatch(updateEngagementTeamDataSources({ data: updatedValues }));
  };

  const handleAddDataSourceClick = (e: any, i: any, item: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(engagementTeamDataSources);
    updatedValues[i].data_source_type = [
      ...updatedValues[i].data_source_type,
      { uid: crypto.randomUUID(), selected_type: '' },
    ];
    dispatch(updateEngagementTeamDataSources({ data: updatedValues }));
  };

  const handleRemoveDataSourceClick = (e: any, i: any, j: any, item: any) => {
    e.preventDefault();

    const updatedValues = structuredClone(engagementTeamDataSources);
    updatedValues[i].data_source_type.splice(j, 1);
    dispatch(updateEngagementTeamDataSources({ data: updatedValues }));
  };

  return (
    <>
      {engagementTeamDataSources?.map((item: any, i: any) => {
        return (
          <div className="data-source-div" key={item.uid}>
            <div className="add-data-source-div">
              <p style={{ fontWeight: 'bold' }}>
                Add Engagement Team Data Source
              </p>
              <button
                className="add-data-source-button"
                onClick={(e) => handleAddDataSourceClick(e, i, item)}
                type="button"
              >
                <AddCircleSharpIcon />
              </button>
            </div>
            {item?.data_source_type?.map((sourceType: any, j: any) => {
              return (
                <div className="data-source-type-div" key={sourceType.uid}>
                  <div className="data-source-type-inner-div">
                    <FormControl required fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Data Source Type
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sourceType.selected_type}
                        label="Data Source Type"
                        onChange={(e: SelectChangeEvent) =>
                          handleInputChange(e, i, item, j)
                        }
                      >
                        <MenuItem value="azure">Microsoft Azure</MenuItem>
                        <MenuItem value="MFT4U">MFT4U</MenuItem>
                      </Select>
                    </FormControl>
                    {sourceType.selected_type === 'Api' && (
                      <ApiDataSource
                        dataType="engagement_team_data_sources"
                        dataTypeIndex={j}
                        dataSourceIndex={i}
                      />
                    )}
                    {sourceType.selected_type === 'azure' && (
                      <AzureDataSource
                        dataType="engagement_team_data_sources"
                        dataTypeIndex={j}
                        dataSourceIndex={i}
                      />
                    )}
                    {sourceType.selected_type === 'MFT4U' && (
                      <MftDataSource
                        dataType="engagement_team_data_sources"
                        dataTypeIndex={j}
                        dataSourceIndex={i}
                      />
                    )}
                  </div>
                  <div>
                    <button
                      className="remove-data-source-button"
                      onClick={(e) =>
                        handleRemoveDataSourceClick(e, i, j, item)
                      }
                    >
                      <RemoveCircleSharpIcon />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default AddEngagementDataSource;
