// @ts-nocheck
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DataTable from '../../components/DataTable/DataTable';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DataTabs(props: any) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {props?.data?.data_source_type?.map(
            (dataSourceType: any, index: any) => {
              return (
                <Tab
                  style={{ textTransform: 'none' }}
                  key={dataSourceType.uid}
                  label={'Data Source ' + (index + 1)}
                  {...a11yProps({ index })}
                />
              );
            },
          )}
        </Tabs>
      </Box>
      {props?.data?.data_source_type?.map(
        (merchant_data_sources: any, index: any) => {
          return (
            <TabPanel
              value={value}
              key={merchant_data_sources.uid}
              index={index}
            >
              <ListItem
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                }}
              >
                {merchant_data_sources.selected_type === 'api' && (
                  <>
                    <ListItemText
                      primary="Type"
                      secondary={
                        merchant_data_sources.selected_type || 'Not Provided'
                      }
                    />
                    <ListItemText
                      className="overflow"
                      style={{ float: 'left', width: '150px' }}
                      primary="URL"
                      secondary={merchant_data_sources.url || 'Not Provided'}
                    />
                    <ListItemText
                      className="overflow"
                      style={{ float: 'left', width: '150px' }}
                      primary="Key"
                      secondary={merchant_data_sources.key || 'Not Provided'}
                    />
                    <ListItemText
                      primary="Username"
                      secondary={
                        merchant_data_sources.user_name || 'Not Provided'
                      }
                    />
                    <ListItemText
                      primary="Password"
                      secondary={
                        merchant_data_sources.password || 'Not Provided'
                      }
                    />
                    <ListItemText
                      primary="Ingestion Start and Data Chasing Start"
                      secondary={
                        merchant_data_sources.ingestion_start_date ||
                        'Not Provided'
                      }
                    />
                    <ListItemText
                      primary="Ingestion End and Data Chasing End"
                      secondary={
                        merchant_data_sources.ingestion_end_date ||
                        'Not Provided'
                      }
                    />
                  </>
                )}
                {merchant_data_sources.selected_type === 'azure' && (
                  <>
                    <ListItemText
                      primary="Type"
                      secondary={
                        merchant_data_sources.selected_type === 'azure'
                          ? 'Microsoft Azure'
                          : 'Not Provided'
                      }
                    />
                    <ListItemText
                      className="overflow"
                      style={{ float: 'left', width: '150px' }}
                      primary="URL"
                      secondary={merchant_data_sources.url || 'Not Provided'}
                    />
                    <ListItemText
                      className="overflow"
                      style={{ float: 'left', width: '150px' }}
                      primary="SAS token"
                      secondary={
                        merchant_data_sources.sas_token || 'Not Provided'
                      }
                    />
                  </>
                )}
                {merchant_data_sources.selected_type === 'MFT4U' && (
                  <>
                    <ListItemText
                      primary="Type"
                      secondary={
                        merchant_data_sources.selected_type || 'Not Provided'
                      }
                    />
                    <ListItemText
                      primary="Email"
                      secondary={merchant_data_sources.email || 'Not Provided'}
                    />
                  </>
                )}
                {merchant_data_sources.selected_type ===
                  'weather_data_rainfall' && (
                  <>
                    <ListItemText
                      primary="Type"
                      secondary="Weather Data (Rainfall)"
                    />
                  </>
                )}
                {merchant_data_sources.selected_type ===
                  'weather_data_temperature' && (
                  <>
                    <ListItemText
                      primary="Type"
                      secondary="Weather Data (Temperature)"
                    />
                  </>
                )}
                {merchant_data_sources.selected_type ===
                  'foreign_exchange_rates' && (
                  <>
                    <ListItemText
                      primary="Type"
                      secondary="Foreign Exchange Rates"
                    />
                  </>
                )}
                {merchant_data_sources.selected_type ===
                  'footfall_historic_data' && (
                  <>
                    <ListItemText
                      primary="Type"
                      secondary="Footfall Historic Data"
                    />
                  </>
                )}
              </ListItem>
              <DataTable data={merchant_data_sources.file} key={index} />
            </TabPanel>
          );
        },
      )}
    </Box>
  );
}
