// @ts-nocheck
import { useState } from 'react';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import AddDataSource from '../../components/AddDataSource/AddDataSource';

import './CreateDataSource.css';

const CreateDataSource = () => {
  const [inputList, setInputList] = useState([
    { firstName: '', lastName: '', uid: crypto.randomUUID() },
  ]);

  // handle click event of the Remove button
  const handleRemoveClick = (e: any, index: any) => {
    e.preventDefault();
    const list = [...structuredClone(inputList)];
    list.splice(index, 1);
    setInputList(list);
  };

  return (
    <>
      {inputList?.map((input, i) => {
        return (
          <div key={input.uid}>
            <div className="main-div">
              <div className="btn-box remove-button-div">
                {inputList.length !== 1 && (
                  <button
                    className="remove-button"
                    onClick={(e) => handleRemoveClick(e, i)}
                  >
                    <RemoveCircleSharpIcon />
                  </button>
                )}
              </div>
            </div>
            <AddDataSource />
          </div>
        );
      })}
    </>
  );
};

export default CreateDataSource;
