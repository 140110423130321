// @ts-nocheck
import React, { useEffect } from 'react';
import dateFormat from 'dateformat';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Cron from 'react-js-cron';
import MultipleSelectCheckbox from '../../components/MultipleSelectCheckbox/MultipleSelectCheckbox';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import MultiEmail from '../MultiEmail/MultiEmail';
import 'react-js-cron/dist/styles.css';
import './ClientDetails.css';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { productUserGuideURLPath } from '../../pages/ProductUserGuide/consts';
import { preventEnterSubmit } from '../../utils/inputs';
  

const ClientDetails = (props: any) => {
  const { myForm, disabled } = props;
  const dispatch = useDispatch();
  const clientName = useSelector((state) => state.formField.client_name);
  const clientEmail = useSelector((state) => state.formField.client_email);
  const multirun = useSelector((state) => state.formField.multirun);
  const engagementName = useSelector(
    (state) => state.formField.engagement_name,
  );
  const engagementDetails = useSelector(
    (state) => state.formField.engagement_details,
  );

  const updateDate = (newValue, path) => {
    if (newValue && !isNaN(new Date(newValue).getTime())) {
      const formattedDate = dateFormat(newValue, 'isoDateTime');
      dispatch(updateFormField({ path, value: formattedDate }));
    } else {
      dispatch(updateFormField({ path, value: '' }));
    }
  };

  useEffect(() => {
    // Set default value for reporting_cycle when the component mounts
    dispatch(updateFormField({ path: ['engagement_details', 'reporting_cycle'], value: 'monthly' }));
  }, []);

  return (
    <>
      <div className="div-style">
        <TextField
          required
          disabled={disabled}
          id="engagement_name"
          label="Engagement Name"
          variant="standard"
          value={engagementName}
          onChange={(e) => {
            dispatch(
              updateFormField({
                key: 'engagement_name',
                value: e.target.value,
              }),
            );
            myForm?.current?.engagement_name?.setCustomValidity('');
          }}
          onKeyDown={preventEnterSubmit}
        />
      </div>

      <div className="div-style">
        <TextField
          required
          id="client_name"
          label="Client Name"
          variant="standard"
          value={clientName}
          onChange={(e) => {
            dispatch(
              updateFormField({ key: 'client_name', value: e.target.value }),
            );
          }}
          onKeyDown={preventEnterSubmit}
        />
        <MultiEmail
          id="client_email"
          label="Client Key Contact Email(s) (not used for data requests)"
          emailsArray={clientEmail}
          handleOnChange={(_emails) => {
            dispatch(updateFormField({ key: 'client_email', value: _emails }));
          }}
          onKeyDown={preventEnterSubmit}
        />
      </div>
      <div className="div-style">
        <TextField
          required
          id="engagement_leader"
          label="Engagement Leader"
          variant="standard"
          value={engagementDetails?.engagement_leader}
          onChange={(e) => {
            dispatch(
              updateFormField({
                path: ['engagement_details', 'engagement_leader'],
                value: e.target.value,
              }),
            );
          }}
          onKeyDown={preventEnterSubmit}
        />
        <TextField
          required
          type="email"
          id="engagement_leader_email"
          label="Engagement Leader Email"
          variant="standard"
          value={engagementDetails?.engagement_leader_email}
          onChange={(e) => {
            dispatch(
              updateFormField({
                path: ['engagement_details', 'engagement_leader_email'],
                value: e.target.value,
              }),
            );
          }}
          onKeyDown={preventEnterSubmit}
        />
      </div>
      <div className="div-style">
        <TextField
          required
          id="engagement_manager"
          label="Engagement Manager"
          variant="standard"
          value={engagementDetails?.engagement_manager}
          onChange={(e) => {
            dispatch(
              updateFormField({
                path: ['engagement_details', 'engagement_manager'],
                value: e.target.value,
              }),
            );
          }}
          onKeyDown={preventEnterSubmit}
        />
        <TextField
          required
          type="email"
          id="engagement_manager_email"
          label="Engagement Manager Email"
          variant="standard"
          value={engagementDetails?.engagement_manager_email}
          onChange={(e) => {
            dispatch(
              updateFormField({
                path: ['engagement_details', 'engagement_manager_email'],
                value: e.target.value,
              }),
            );
          }}
          onKeyDown={preventEnterSubmit}
        />
      </div>
      <div>
        <MultiEmail
          id="engagement_team"
          label="Engagement Team Member Emails*"
          emailsArray={engagementDetails?.engagement_team}
          handleOnChange={(_emails) => {
            dispatch(
              updateFormField({
                path: ['engagement_details', 'engagement_team'],
                value: _emails,
              }),
            );
          }}
        />
        <em>In order for users to get access to Databricks databases and Airflow DAGs for this engagement, they need to be added as engagement team members, an engagement manager or an engagement leader.</em>
      </div>
      <div className="div-style">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Engagement Start Date (mm/dd/yyyy)"
            value={engagementDetails?.engagement_start_date}
            onChange={(newValue) =>
              updateDate(newValue, [
                'engagement_details',
                'engagement_start_date',
              ])
            }
            renderInput={(params) => (
              <TextField
                id="engagement_start_date"
                required
                {...params}
                onKeyDown={preventEnterSubmit}
              />
            )}
          />
          <DatePicker
            label="Engagement End Date (mm/dd/yyyy)"
            required
            value={engagementDetails?.engagement_end_date}
            onChange={(newValue) =>
              updateDate(newValue, [
                'engagement_details',
                'engagement_end_date',
              ])
            }
            renderInput={(params) => (
              <TextField
                id="engagement_end_date"
                required
                {...params}
                onKeyDown={preventEnterSubmit}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div>
        <em>
            None of the processes (Airflow DAGs) will be run outside of the engagement period range, so please make sure that Engagement Start Date and Engagement End Date are set correctly.
        </em>
      </div>
      <div className="div-style">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="First Reporting Period Start Date (mm/dd/yyyy)"
            value={engagementDetails?.first_reporting_period_start}
            onChange={(newValue) =>
              updateDate(newValue, [
                'engagement_details',
                'first_reporting_period_start',
              ])
            }
            renderInput={(params) => (
              <TextField
                id="first_reporting_period_start"
                required
                {...params}
                onKeyDown={preventEnterSubmit}
              />
            )}
          />
          <DatePicker
            label="First Reporting Period End Date (mm/dd/yyyy)"
            value={engagementDetails?.first_reporting_period_end}
            onChange={(newValue) =>
              updateDate(newValue, [
                'engagement_details',
                'first_reporting_period_end',
              ])
            }
            renderInput={(params) => (
              <TextField
                id="first_reporting_period_end"
                required
                {...params}
                onKeyDown={preventEnterSubmit}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div>
        <em>
            The First Reporting Period Start Date should be the first date of a calendar month. The First Reporting Period End Date should be the last date of a calendar month.
        </em>
      </div>
      <div className="div-style">
        <FormControl required fullWidth>
          <InputLabel id="multirun">
            Will the pipeline be run multiple times?
          </InputLabel>
          <Select
            labelId="multirun"
            id="multirun"
            required
            value={multirun}
            label="Will the pipeline be run multiple times?"
            onChange={(e) => {
              dispatch(
                updateFormField({ key: 'multirun', value: e.target.value }),
              );
            }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl required fullWidth disabled>
          <InputLabel id="reporting_cycle">Reporting Cycle</InputLabel>
          <Select
            labelId="reporting_cycle"
            required
            id="reporting_cycle"
            value={engagementDetails?.reporting_cycle || 'monthly'}
            label="reporting_cycle"
            onChange={(e) => {
              dispatch(
                updateFormField({
                  path: ['engagement_details', 'reporting_cycle'],
                  value: e.target.value,
                }),
              );
            }}
          >
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="biweekly">Biweekly</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Typography>
        For guidance on dates, please refer to{' '}
        <Link
          to={`/${productUserGuideURLPath}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Product User Guide
        </Link>
      </Typography>
      {multirun !== 'No' && (<div className="date-container">
        <div className="date-title">
          Ingestion Start and Data Chasing Start{' '}
        </div>
        <div>
          <Cron
            value={engagementDetails?.ingest_cycle_start}
            setValue={(val) => {
              dispatch(
                updateFormField({
                  path: ['engagement_details', 'ingest_cycle_start'],
                  value: val,
                }),
              );
            }}
          />
          <span className="date-span">
            The date and time when the data ingestion pipeline is automatically run for the first time and the date when the transformation and validation pipeline is run for the first time and the first chaser emails are sent.
          </span>
        </div>
      </div>
      )}
      {multirun !== 'No' && (<div className="date-container">
        <div className="date-title">Ingestion End and Data Chasing End </div>
        <div>
          <Cron
            value={engagementDetails?.ingest_cycle_end}
            setValue={(val) => {
              dispatch(
                updateFormField({
                  path: ['engagement_details', 'ingest_cycle_end'],
                  value: val,
                }),
              );
            }}
          />
          <span className="date-span">
            The date and time when the data ingestion pipeline is automatically run for the last time and the date when the transformation and validation pipeline is run for the last time and the last chaser emails are sent.
          </span>
        </div>
      </div>)}

      {multirun !== 'No' && (<div className="date-container">
        <div className="date-title">Data Analysis and Reporting Date</div>
        <div>
          <Cron
            value={engagementDetails?.data_ingest_process_schedule}
            setValue={(val) => {
              dispatch(
                updateFormField({
                  path: ['engagement_details', 'data_ingest_process_schedule'],
                  value: val,
                }),
              );
            }}
          />
          <span className="date-span">
            The date and time when data analysis and reporting pipeline is run.
          </span>
        </div>
      </div>)}
      <MultipleSelectCheckbox />
    </>
  );
};

export default ClientDetails;
