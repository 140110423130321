import { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DATA_TYPE } from '../../constants/file';

const CopyFile = ({
  selectedValue,
  onChangeSelect,
  selectItems,
  onSubmitButton,
  isDisabledSubmit,
  isFinalAccordion = false,
  formFieldDataSource,
}) => {
  return (
    <div className="add-button-div">
      <p style={{ width: '100%' }}>Copy from...</p>
      <FormControl
        className="dropdown-form-control copy-file-button-select"
        disabled={!selectItems.length}
      >
        <InputLabel id="file-label" disabled={!selectItems.length}>
          File
        </InputLabel>
        <Select
          labelId="file-label"
          value={selectedValue}
          label="File"
          onChange={onChangeSelect}
          className="dropdown-select"
          disabled={!selectItems.length}
        >
          {selectItems?.map((file, index) => (
            <MenuItem key={index} value={index}>
              {`${DATA_TYPE[file.data_type]} | ${file.merchant_name.join(
                ', ',
              )} | ${file.acquirer_name.join(', ')} | ${file.file_name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <button
        className="copy-file-button"
        onClick={(e) => onSubmitButton(e)}
        type="button"
        disabled={isDisabledSubmit}
      >
        {`Copy`}
      </button>
    </div>
  );
};

export default CopyFile;
