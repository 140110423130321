import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Argument, Row } from '../../types/reviewType';

const ReviewTable = (props: any) => {
  const { items, title } = props;

  function createData(name: string, argumentsList: Argument[]) {
    return { name, argumentsList };
  }

  const rows = [] as Row[];

  items.map((item: { name: string; arguments: Argument[] }) => {
    const tempArguments = structuredClone(item.arguments);
    return [rows.push(createData(item.name, tempArguments))];
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 450 }}>{title}</TableCell>
            <TableCell>Argument List</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: Row, index: number) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell scope="row">{row.name}</TableCell>
              <TableCell scope="row">
                {row.argumentsList?.map((arg: any) =>
                  Object.keys(arg).map((k: string, index: number) => {
                    if (k === 'is_mandatory' || k === 'python_datatype') {
                      delete arg[k];
                    } else {
                      return <p key={index}>{`${k} (${arg[k]})`}</p>;
                    }
                  }),
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReviewTable;
