// @ts-nocheck
import * as React from 'react';
import { useState, useEffect } from 'react';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import AddArgumentList from '../../components/AddArgumentList/AddArgumentList';
import { useSelector, useDispatch } from 'react-redux';
import { Transformation, Field } from '../../types/transformationType';
import { TRANSFORMATIONS } from '../../constants/file';
import './AddTransformation.css';
import { RootState } from '../../rtk/store';
import { prepareArgument } from '../../utils/inputs';
import { updateFormField } from '../../slices/formFieldSlice';

const AddTransformation = (props: any) => {
  const {
    colIndex,
    pathToSourceType,
    fileIndex,
    dataType,
    dataSourceIndex,
    dataTypeIndex,
  } = props;
  const dispatch = useDispatch();
  const pathToTransformation = [
    ...pathToSourceType,
    'file',
    fileIndex,
    'transformation',
  ];
  const formFieldTransformations = useSelector(
    (state) =>
      ['formField', ...pathToTransformation].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );
  const [transformationFields, setTransformationFields] = useState<Field[]>([]);

  const transformationsSelector = useSelector(
    (state: RootState) => state.transformations.transformations,
  );

  const transformationFieldsSelector = useSelector(
    (state: RootState) => state.transformations.transformationsFields,
  );

  useEffect(() => {
    const myClonedArray = [];
    transformationFieldsSelector?.forEach((val) => {
      if (val.name !== 'drop_null_columns') {
        myClonedArray.push(Object.assign({}, val));
      }
    });
    transformationFieldsSelector.length > 0 &&
      setTransformationFields(myClonedArray);
  }, [transformationsSelector, transformationFieldsSelector]);

  const handleRemoveClick = (e: any, index: any) => {
    e.preventDefault();
    const editedFormFieldTransformations = structuredClone(
      formFieldTransformations,
    );
    editedFormFieldTransformations.splice(index, 1);
    dispatch(
      updateFormField({
        path: pathToTransformation,
        value: editedFormFieldTransformations,
      }),
    );
  };

  const handleSelectedTransformation = (e: any) => {
    e.preventDefault();

    const selectedTransformationTemp = structuredClone(
      transformationFields,
    )?.find((item) => item.name === e.target.value);

    const selectedTransformationArguments =
      selectedTransformationTemp?.arguments.map((item) =>
        prepareArgument(item),
      );

    selectedTransformationTemp.arguments = selectedTransformationArguments;
    const editedFormFieldTransformations = structuredClone(
      formFieldTransformations,
    );

    editedFormFieldTransformations[colIndex] = selectedTransformationTemp;
    if (
      !editedFormFieldTransformations[colIndex].uid &&
      formFieldTransformations[colIndex].uid
    ) {
      editedFormFieldTransformations[colIndex].uid =
        formFieldTransformations[colIndex].uid;
    }

    dispatch(
      updateFormField({
        path: pathToTransformation,
        value: editedFormFieldTransformations,
      }),
    );
  };

  const setDisabled = (selectedItem) => {
    const contain = formFieldTransformations.filter(
      (item) => item.name === selectedItem.name,
    );
    return contain.length > 0;
  };

  const sortTransformationItems = (array: []) => {
    array.sort((a, b) => {
      if (!TRANSFORMATIONS[a.name]) {
        return a.name
          .replace(/_/g, ' ')
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
          .localeCompare(TRANSFORMATIONS[b.name]);
      } else if (!TRANSFORMATIONS[b.name]) {
        return TRANSFORMATIONS[a.name].localeCompare(
          b.name
            .replace(/_/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        );
      } else if (!TRANSFORMATIONS[a.name] && !TRANSFORMATIONS[b.name]) {
        return a.name
          .replace(/_/g, ' ')
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
          .localeCompare(
            b.name
              .replace(/_/g, ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
          );
      } else {
        return TRANSFORMATIONS[a.name].localeCompare(TRANSFORMATIONS[b.name]);
      }
    });

    return array;
  };

  const transformationItems =
    transformationFields.length > 0
      ? sortTransformationItems(transformationFields)
      : [];
  const availableValues = transformationItems.map((item) => item.name);
  const validValue = availableValues.includes(
    formFieldTransformations[colIndex].name,
  )
    ? formFieldTransformations[colIndex].name
    : '';

  return (
    <div className="column-transformation-div">
      <div className="row-div">
        <FormControl required fullWidth>
          <InputLabel id="demo-simple-select-label1">
            Select a transformation
          </InputLabel>
          <Select
            required
            labelId="demo-simple-select-label1"
            id="demo-simple-select1"
            value={validValue}
            label="Select a transformation"
            name="dataType"
            defaultValue="rename_column"
            variant="standard"
            onChange={handleSelectedTransformation}
          >
            {transformationItems.map((item: Field, index: number) => {
              return (
                <MenuItem key={index} value={item.name}>
                  {TRANSFORMATIONS[item.name] ||
                    item.name
                      .replace(/_/g, ' ')
                      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase(),
                      )}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText className="helper-text">
            {formFieldTransformations[colIndex].description || ''}
          </FormHelperText>
        </FormControl>
        <div>
          <button
            type="button"
            className="remove-merchant-button"
            onClick={(e) => handleRemoveClick(e, colIndex)}
          >
            <RemoveCircleSharpIcon />
          </button>
        </div>
      </div>
      <AddArgumentList
        selectedTransformation={formFieldTransformations[colIndex]}
        dataType={dataType}
        fileIndex={fileIndex}
        colIndex={colIndex}
        dataSourceIndex={dataSourceIndex}
        dataTypeIndex={dataTypeIndex}
        type="transformation"
      />
    </div>
  );
};

export default AddTransformation;
