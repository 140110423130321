// @ts-nocheck
import './styles.css';

import React, { useState } from 'react';
import { useDidUpdateEffect } from './use-did-update-effect';

import cc from './classnames';
import Tag from './tag';

export interface TagsInputProps {
  name?: string;
  type?: string;
  required?: boolean;
  placeHolder?: string;
  value?: string[];
  onChange?: (tags: string[]) => void;
  onBlur?: any;
  separators?: string[];
  disableBackspaceRemove?: boolean;
  onExisting?: (tag: string) => void;
  onRemoved?: (tag: string) => void;
  disabled?: boolean;
  isEditOnRemove?: boolean;
  beforeAddValidate?: (tag: string, existingTags: string[]) => boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  classNames?: {
    input?: string;
    tag?: string;
  };
}

const defaultSeparators = ['Enter'];

export const TagsInput = ({
  name,
  type,
  required,
  placeHolder,
  value,
  onChange,
  onBlur,
  separators,
  disableBackspaceRemove,
  onExisting,
  onRemoved,
  disabled,
  isEditOnRemove,
  beforeAddValidate,
  onKeyUp,
  classNames,
}: TagsInputProps) => {
  const [tags, setTags] = useState<any>(value || []);

  useDidUpdateEffect(() => {
    onChange && onChange(tags);
  }, [tags]);

  useDidUpdateEffect(() => {
    if (
      JSON.stringify(value) !== JSON.stringify(tags) &&
      Array.isArray(value)
    ) {
      setTags(value);
    }
  }, [value]);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  const handleOnKeyUp = (e) => {
    e.stopPropagation();

    const text = e.target.value;
    if (
      !text &&
      !disableBackspaceRemove &&
      tags.length &&
      e.key === 'Backspace'
    ) {
      e.target.value = isEditOnRemove ? `${tags.at(-1)} ` : '';
      setTags((prevTags) => [...prevTags.slice(0, -1)]);
    }

    if (text && (separators || defaultSeparators).includes(e.key)) {
      e.preventDefault();
      if (
        beforeAddValidate &&
        !beforeAddValidate(text, tags) &&
        validateEmail(text) !== null
      )
        return;

      if (tags && tags.includes(text) && validateEmail(text) !== null) {
        onExisting && onExisting(text);
        return;
      }
      if (type === 'email') {
        if (validateEmail(text) !== null) {
          setTags((prevTags = []) => [...prevTags, text]);
          e.target.value = '';
        }
      } else {
        setTags((prevTags = []) => [...prevTags, text]);
        e.target.value = '';
      }
    }
  };

  const onTagRemove = (text) => {
    setTags(tags.filter((tag) => tag !== text));
    onRemoved && onRemoved(text);
  };

  return (
    <div aria-labelledby={name} className="rti--container">
      {tags &&
        tags.map((tag) => (
          <Tag
            key={tag}
            className={classNames?.tag}
            text={tag}
            remove={onTagRemove}
            disabled={disabled}
          />
        ))}

      <input
        className={cc('rti--input', classNames?.input)}
        required={tags && tags.length === 0 ? required : ''}
        type={type === 'email' ? 'email' : 'text'}
        name={name}
        placeholder={placeHolder}
        onKeyDown={handleOnKeyUp}
        onBlur={onBlur}
        disabled={disabled}
        onKeyUp={onKeyUp}
      />
    </div>
  );
};
