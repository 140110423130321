// @ts-nocheck
import * as React from 'react';
import './AddFinalArgumentList.css';
import { Argument } from '../../types/finalStepType';
import { getDataType, getArgumentInformations } from '../../utils/inputs';

import ItemBoolean from '../Form/ItemBoolean';
import ItemList from '../Form/ItemList';
import ItemDict from '../Form/ItemDict';
import ItemElse from '../Form/ItemElse';

const AddFinalArgumentList = ({
  selectedStep,
  list,
  finalItem,
  colIndex,
}: any) => {
  const contextHandleDataTypeChange = {
    dataType: list, // final_transformation or final_validation
    finalItemName: finalItem.name, //formField[dataType][finalItemName][arguments][colIndex]
    colIndex,
    selectedStep: structuredClone(selectedStep),
  };

  return (
    <div className="column-div">
      {selectedStep?.arguments?.map((item: Argument, index: number) => {
        const { isList, isDict, uid } = getArgumentInformations(item);
        return (
          <div key={uid} className="container">
            {getDataType(item.python_datatype) === 'boolean' ? (
              <ItemBoolean
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            ) : isList ? (
              <ItemList
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            ) : isDict ? (
              <ItemDict
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            ) : (
              <ItemElse
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            )}
            {['dict[str, str]', 'dict[str]', 'list[str]', 'list'].includes(
              item.type,
            ) && (
              <div style={{ fontSize: '10px', color: 'grey' }}>
                Add comma separated values
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AddFinalArgumentList;
